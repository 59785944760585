import { render, staticRenderFns } from "./password-forgot.vue?vue&type=template&id=4610a205"
import script from "./password-forgot.vue?vue&type=script&lang=js"
export * from "./password-forgot.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports